
import React, { useState, useEffect } from 'react';

const DarkModeSwitch = ({ toggleDarkMode, isDarkMode, counter }) => {

  useEffect(() => {
    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);


  return (
    <label className="switch">
      <input type="checkbox" onChange={toggleDarkMode} checked={isDarkMode} disabled={counter >= 10 ? true : false} />
      <span className="slider round"></span>
    </label>
  );
};

export default DarkModeSwitch;
