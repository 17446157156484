import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

const InfoBox = ({
  sensorIDs,
  selectedSensorId,
  historicalCpuData,
  isDarkMode,
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const intervalRef = useRef(null);

  function GetSensorInfo(id) {
    if (id === sensorIDs[0]) {
      return {
        id: sensorIDs[0],
        position: "Front",
        alertlow: "15",
        alerthigh: "35",
      };
    }
    if (id === sensorIDs[1]) {
      return {
        id: sensorIDs[1],
        position: "Innenraum",
        alertlow: "20",
        alerthigh: "55",
      };
    }
    if (id === sensorIDs[2]) {
      return {
        id: sensorIDs[2],
        position: "Kühltühr",
        alertlow: "12",
        alerthigh: "35",
      };
    }
    if (id === sensorIDs[3]) {
      return {
        id: sensorIDs[3],
        position: "Rücklauf",
        alertlow: "12",
        alerthigh: "30",
      };
    }
    return null;
  }

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      const textColor = isDarkMode ? "#fff" : "";
      const datasets = [
        {
          label: "Cpu Temp",
          data: historicalCpuData.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor:
            selectedSensorId === sensorIDs[4] ? "#b92d41" : "#F18F1F",
          backgroundColor: "#F18F1F",
          borderWidth: selectedSensorId === sensorIDs[4] ? 3 : 1,
          hidden: false,
        },
      ];
      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          datasets: datasets,
        },
        options: {
          plugins: {
            legend: {
              position: "top",
              labels: {
                color: textColor,
                font: {
                  size: 14,
                  family: "Regular",
                  color: textColor,
                },
                boxWidth: 20,
                boxHeight: 20,
              },
            },
          },
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "hour",
              },
              title: {
                display: true,
                text: "Time",
                color: textColor,
              },
              ticks: {
                color: textColor,
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Value",
              },
              ticks: {
                color: textColor,
              },
            },
          },
        },
      });
    }
  }, [historicalCpuData, selectedSensorId, isDarkMode]);

  const sensorInfo = GetSensorInfo(selectedSensorId);

  return (
    <div className="info-box">
      <div className="historical-cpu-chart">
        <div className="header">
          <h1>Cpu temperature</h1>
        </div>
        <canvas ref={chartRef} id="historicalcpuChart"></canvas>
      </div>
    </div>
  );
};

export default InfoBox;
