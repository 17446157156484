import React, { useState, useEffect } from "react";
import useApi from "../services/api";

const SensorDataContainer = ({
  sensorType,
  onSensorChange,
  sensorIds,
  selectedSensorId,
  doorIds,
  leakageIds,
}) => {
  const [temperatures, setTemperatures] = useState({});
  const [leakageStatus, setLeakageStatus] = useState({});
  const [doorStatus, setDoorStatus] = useState({});
  const { fetchAnyStatus } = useApi();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const tempData = {};
        await Promise.all(
          sensorIds.map(async (id) => {
            const result = await fetchAnyStatus(id, sensorType);
            tempData[id] = result.value;
          })
        );
        setTemperatures(tempData);

        const doorData = {};
        await Promise.all(
          doorIds.map(async (id) => {
            const result = await fetchAnyStatus(id, sensorType);
            doorData[id] = result.value;
          })
        );
        setDoorStatus(doorData);

        const leakageData = {};
        await Promise.all(
          leakageIds.map(async (id) => {
            const result = await fetchAnyStatus(id, sensorType);
            leakageData[id] = result.value;
          })
        );
        setLeakageStatus(leakageData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sensorType]);

  const handleButtonClick = (sensorId) => {
    onSensorChange(sensorId);
  };

  function getTemperatureColorClass(temperature) {
    if (temperature <= 10) {
      return "cold";
    } else if (temperature > 10 && temperature <= 25) {
      return "normal";
    } else {
      return "hot";
    }
  }

  return (
    <div className="sensor-data-container">
      {sensorIds.map((id) => (
        <div
          className={`sensor-box ${getTemperatureColorClass(
            temperatures[id]
          )} ${selectedSensorId === id ? "selected" : ""}`}
          key={id}
          onClick={() => handleButtonClick(id)}
        >
          <div className="sensor-icon">
            <div className="sensor-header">Temp {id}</div>
          </div>
          <div className="sensor-info">
            {temperatures[id] !== undefined && (
              <span>{temperatures[id]}°C</span>
            )}
          </div>
        </div>
      ))}
      {doorIds.map((id) => (
        <div
          className={`sensor-box ${doorStatus[id] ? "detected" : "undetected"}`}
          key={id}
        >
          <div className="sensor-icon">
            <div className="sensor-header">Door {id}</div>
          </div>
          <div className="sensor-info">
            {doorStatus[id] !== undefined && (
              <span>{doorStatus[id] ? "open" : "closed"}</span>
            )}
          </div>
        </div>
      ))}
      {leakageIds.map((id) => (
        <div
          className={`sensor-box ${
            leakageStatus[id] ? "detected" : "undetected"
          }`}
          key={id}
          
        >
          
          <div className="sensor-icon">
            <div className="sensor-header">Leakage {id}</div>
          </div>
          <div className="sensor-info">
            {leakageStatus[id] !== undefined && (
              <span>{leakageStatus[id] ? "detected" : "undetected"}</span>
            )}
          </div>
        </div>

      ))}

    </div>

  );
};

export default SensorDataContainer;
