import React from 'react';
const EasterEgg = ({counter}) => {
  return (

    <div>
      { counter >= 10 &&<img className='cat2' src="./cat2.png" />}
      <img className='cat' src="./cat.png" />
       { counter == 8 && <p className='catdiv'>STOP IT OR I WILL DECIDE FOR YOU</p>}
       { counter == 9 &&<p className='catdiv'>I SAID STOP</p>}
       { counter == 10 &&<p className='catdiv'>NOW ITS YOUR PROBLEM</p>}
    </div>
  );
};

export default EasterEgg;