import axios from 'axios';
import { useState } from 'react';

const useApi = () => {
  const [baseUrl, setBaseUrl] = useState('https://test.tamino089.com/api');

  const setAPIConfigs = (domain) => {
    setBaseUrl(domain);
  };

  const fetchAnyStatus = (sensorId, sensorType) => {
    return axios.get(`${baseUrl}/${sensorType}/${sensorId}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching status:', error);
        return [];
      });
  };

  const fetchHistoricalData = (sensorId) => {
    return axios.get(`${baseUrl}/historical_data/${sensorId}`)
      .then(response => {
        console.log('Historical Data:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching historical data:', error);
        return [];
      });
  };

  const fetchHistoricalCputemps = () => {
    return axios.get(`${baseUrl}/historical_cputemps/`)
      .then(response => {
        console.log('Historical Cputemps:', response.data);
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching historical data:', error);
        return [];
      });
  };

  return { setAPIConfigs, fetchAnyStatus, fetchHistoricalData, fetchHistoricalCputemps };
};

export default useApi;
