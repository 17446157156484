import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import useApi from "../services/api";

const TrackerWin = ({ sensorIDs, selectedSensorId, isDarkMode}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const intervalRef = useRef(null);
  const [intervalSeconds, setIntervalSeconds] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [liveData1, setLiveData1] = useState([]);
  const [liveData2, setLiveData2] = useState([]);
  const [liveData3, setLiveData3] = useState([]);
  const [liveData4, setLiveData4] = useState([]);
  const [liveData5, setLiveData5] = useState([]);
  const { fetchAnyStatus } = useApi();


  const MAX_DATA_POINTS = 10;

  const fetchLiveData = async () => {
    try {
      const data1 = await fetchAnyStatus(sensorIDs[0], "temp");
      const data2 = await fetchAnyStatus(sensorIDs[1], "temp");
      const data3 = await fetchAnyStatus(sensorIDs[2], "temp");
      const data4 = await fetchAnyStatus(sensorIDs[3], "temp");
      const data5 = await fetchAnyStatus(sensorIDs[4], "temp");

      const timestamp = new Date().toISOString();

      setLiveData1((liveData1) => {
        const updatedData = [...liveData1, { timestamp, value: data1.value }];
        return updatedData.length > MAX_DATA_POINTS ? updatedData.slice(1) : updatedData;
      });
      setLiveData2((liveData2) => {
        const updatedData = [...liveData2, { timestamp, value: data2.value }];
        return updatedData.length > MAX_DATA_POINTS ? updatedData.slice(1) : updatedData;
      });
      setLiveData3((liveData3) => {
        const updatedData = [...liveData3, { timestamp, value: data3.value }];
        return updatedData.length > MAX_DATA_POINTS ? updatedData.slice(1) : updatedData;
      });
      setLiveData4((liveData4) => {
        const updatedData = [...liveData4, { timestamp, value: data4.value }];
        return updatedData.length > MAX_DATA_POINTS ? updatedData.slice(1) : updatedData;
      });
      setLiveData5((liveData5) => {
        const updatedData = [...liveData5, { timestamp, value: data4.value }];
        return updatedData.length > MAX_DATA_POINTS ? updatedData.slice(1) : updatedData;
      });
    } catch (error) {
      console.error("Error fetching live data:", error);
    }
  };


  const handleStart = () => {
    if(intervalSeconds < 1){
      return
    }
    if (intervalSeconds && !isNaN(intervalSeconds)) {
      const interval = parseInt(intervalSeconds, 10) * 1000;
      intervalRef.current = setInterval(fetchLiveData, interval);
      setIsRunning(true);
    }
  };

  const handleStop = () => {
    clearInterval(intervalRef.current);
    setIsRunning(false);
  };


  useEffect(() => {
    if (chartRef.current && chartInstance.current) {
      const textColor = isDarkMode ? "#fff" : "#000"; // Standardtextfarbe für den Hellmodus
      chartInstance.current.options.scales.x.title.color = textColor;
      chartInstance.current.options.scales.x.ticks.color = textColor;
      chartInstance.current.options.scales.y.title.color = textColor;
      chartInstance.current.options.scales.y.ticks.color = textColor;
      chartInstance.current.options.plugins.legend.labels.color = textColor;
      chartInstance.current.update();
    }
  }, [isDarkMode]);


  useEffect(() => {

    if (chartRef.current && !chartInstance.current) {
      const ctx = chartRef.current.getContext("2d");
      const textColor = isDarkMode ? "#fff" : "";
      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          datasets: [
            {
              label: "Sensor 1",
              data: [],
              borderColor: "#F18F1F",
              backgroundColor: "#F18F1F",
              borderWidth: 1,
              hidden: true,
            },
            {
              label: "Sensor 2",
              data: [],
              borderColor: "#00b1aa",
              backgroundColor: "#00b1aa",
              borderWidth: 1,
              hidden: true,
            },
            {
              label: "Sensor 3",
              data: [],
              borderColor: "#828728",
              backgroundColor: "#828728",
              borderWidth: 1,
              hidden: true,
            },
            {
              label: "Sensor 4",
              data: [],
              borderColor: "#917db9",
              backgroundColor: "#917db9",
              borderWidth: 1,
              hidden: true,
            },
          ],
          
        },
        
        options: {
          plugins: {
            legend: {
              position: "top",
              labels: {
                color: textColor,
                font: {
                  size: 14,
                  family: "Regular"
                },
                boxWidth: 20,
                boxHeight: 20,
              }
            }
          },
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "minute",
              },
              title: {
                display: true,
                text: "Time",
                color: textColor,
              },
              ticks: {
                color: textColor,
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Value",
                color: textColor,
              },

              ticks: {
                color: textColor,
              },
            },
          },
        },
      });
    }
  }, [isDarkMode]);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.data.datasets = [
        
        {
          label: sensorIDs[0],
          data: liveData1.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor: selectedSensorId === sensorIDs[0] ? "#b92d41" : "#F18F1F",
          backgroundColor: "#F18F1F",
          borderWidth: selectedSensorId === sensorIDs[0] ? 3 : 1,
          hidden: false,
        },
        {
          label: sensorIDs[1],
          data: liveData2.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor: selectedSensorId === sensorIDs[1] ? "#b92d41" : "#00b1aa",
          backgroundColor: "#00b1aa",
          borderWidth: selectedSensorId === sensorIDs[1] ? 3 : 1,
          hidden: false,
        },
        {
          label: sensorIDs[2],
          data: liveData3.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor: selectedSensorId === sensorIDs[2] ? "#b92d41" : "#828728",
          backgroundColor: "#828728",
          borderWidth: selectedSensorId === sensorIDs[2] ? 3 : 1,
          hidden: false,
        },
        {
          label: sensorIDs[3],
          data: liveData4.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor: selectedSensorId === sensorIDs[3] ? "#b92d41" : "#917db9",
          backgroundColor: "#917db9",
          borderWidth: selectedSensorId === sensorIDs[3] ? 3 : 1,
          hidden: false,
        },
        {
          label: sensorIDs[4],
          data: liveData5.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor: selectedSensorId === sensorIDs[4] ? "#b92d41" : "#917db9",
          backgroundColor: "#917db9",
          borderWidth: selectedSensorId === sensorIDs[4] ? 3 : 1,
          hidden: false,
        },
        
        
      ];
      chartInstance.current.update();
    }
    
    
  }, [liveData1, liveData2, liveData3, liveData4, selectedSensorId, isDarkMode]);
  

  const handleInputChange = (e) => {
    setIntervalSeconds(e.target.value);
  };

  return (
    <div className="tracker-win">
      <div className="header">
        <h1>Abtaster</h1>
      </div>
      <div className="chart-container">
        <canvas ref={chartRef} className="chart"></canvas>
      </div>
      <div className="controls">
        <input
          type="number"
          value={intervalSeconds}
          onChange={handleInputChange}
          disabled={isRunning}
          placeholder="Interval in seconds"
          className="interval-input"
        />
        <button onClick={handleStart} disabled={isRunning} className="start-button">
          {isRunning ? "Running..." : "Start"}
        </button>
        <button onClick={handleStop} disabled={!isRunning} className="stop-button">
          Stop
        </button>
      </div>
    </div>
  );
};

export default TrackerWin;
