import React, { useState, useEffect } from "react";
import "./App.css";
import SensorDataContainer from "./components/SensorDataContainer";
import HistoricalDataChart from "./components/HistoricalDataChart";
import useApi from "./services/api";
import Trackerwin from "./components/trackerwin";
import InfoBox from "./components/InfoBox";
import DarkModeSwitch from "./components/DarkoModeSwitch";
import EasterEgg from "./components/EasterEgg";
import ConfigModal from './components/ConfigModal';

function App() {
  const doorIds = ["1"];
  const leakageIds = ["1"];
  const sensorIds = ["Xd5", "Xd6", "Xd7", "Xd4", "cpu"];
  const [sensorType, setSensorType] = useState("temp");
  const [sensorId, setSensorId] = useState("");
  const [historicalData1, setHistoricalData1] = useState([]);
  const [historicalData2, setHistoricalData2] = useState([]);
  const [historicalData3, setHistoricalData3] = useState([]);
  const [historicalData4, setHistoricalData4] = useState([]);
  const [historicalCputemps, sethistoricalCputemps] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [counter, setCounter] = useState(0)

  const { fetchHistoricalData, fetchHistoricalCputemps } = useApi();



  useEffect(() => {
    async function fetchData() {
      await fetchChartData();
      await fetchChartCputemps();
    }
    fetchData();
  }, []);



  
  const fetchChartCputemps = async () => {
    try {
      const [Cputemps] = await Promise.all([fetchHistoricalCputemps()]);
      sethistoricalCputemps(Cputemps);
    } catch (error) {
      console.error("Error fetching historical data:", error);
    }
  };

  const fetchChartData = async () => {
    try {
      const [data1, data2, data3, data4] = await Promise.all([
        fetchHistoricalData(sensorIds[0]),
        fetchHistoricalData(sensorIds[1]),
        fetchHistoricalData(sensorIds[2]),
        fetchHistoricalData(sensorIds[3]),
      ]);
      setHistoricalData1(data1);
      setHistoricalData2(data2);
      setHistoricalData3(data3);
      setHistoricalData4(data4);
    } catch (error) {
      console.error("Error fetching historical data:", error);
    }
  };

  const handleSensorChange = (sensor) => {
    if (sensor === sensorId) {
      setSensorId("");
    } else {
      setSensorId(sensor);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    setCounter(counter +1)
    console.log(isDarkMode);
  };

  return (
    <div className="App" data-theme={isDarkMode ? "dark" : "light"}>
      <div className={`container`}>
        <div className="parent">
          <div className="switch-parent">
            <DarkModeSwitch
              toggleDarkMode={toggleDarkMode}
              isDarkMode={isDarkMode}
              counter={counter}
            />
            <ConfigModal/>
          </div>
          <div className={`header `}>
            <h1 id="titel">Sensor Dashboard</h1>
          </div>
          <InfoBox
            sensorIDs={sensorIds}
            historicalData1={historicalData1}
            historicalData2={historicalData2}
            historicalData3={historicalData3}
            historicalData4={historicalData4}
            selectedSensorId={sensorId}
            doorIds={doorIds}
            leakageIds={leakageIds}
            historicalCpuData={historicalCputemps}
            isDarkMode={isDarkMode}
          />
          <SensorDataContainer
            onSensorChange={handleSensorChange}
            sensorType={sensorType}
            sensorIds={sensorIds}
            selectedSensorId={sensorId}
            doorIds={doorIds}
            leakageIds={leakageIds}
          />
          <HistoricalDataChart
            sensorIds={sensorIds}
            historicalData1={historicalData1}
            historicalData2={historicalData2}
            historicalData3={historicalData3}
            historicalData4={historicalData4}
            selectedSensorId={sensorId}
            doorIds={doorIds}
            leakageIds={leakageIds}
            isDarkMode={isDarkMode}
          />
          <Trackerwin
            selectedSensorId={sensorId}
            sensorIDs={sensorIds}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
      {counter > 7 && <EasterEgg counter={counter}></EasterEgg>}
    </div>
  );
}

export default App;
