import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

const HistoricalDataChart = ({
  historicalData1,
  historicalData2,
  historicalData3,
  historicalData4,
  selectedSensorId,
  sensorIds,
  isDarkMode
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      const textColor = isDarkMode ? "#fff" : "";

      const datasets = [
        {
          label: sensorIds[0],
          data: historicalData1.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor:
            selectedSensorId === sensorIds[0] ? "#b92d41" : "#F18F1F",
          backgroundColor: "#F18F1F",
          borderWidth: selectedSensorId === sensorIds[0] ? 3 : 1,
          hidden: false,
        },
        {
          label: sensorIds[1],
          data: historicalData2.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor:
            selectedSensorId === sensorIds[1] ? "#b92d41" : "#00b1aa",
          backgroundColor: "#00b1aa",
          borderWidth: selectedSensorId === sensorIds[1] ? 3 : 1,
          hidden: false,
        },
        {
          label: sensorIds[2],
          data: historicalData3.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor:
            selectedSensorId === sensorIds[2] ? "#b92d41" : "#828728",
          backgroundColor: "#828728",
          borderWidth: selectedSensorId === sensorIds[2] ? 3 : 1,
          hidden: false,
        },
        {
          label: sensorIds[3],
          data: historicalData4.map((data) => ({
            x: new Date(data.timestamp),
            y: data.value,
          })),
          borderColor:
            selectedSensorId === sensorIds[3] ? "#b92d41" : "#917db9",
          backgroundColor: "#917db9",
          borderWidth: selectedSensorId === sensorIds[3] ? 3 : 1,
          hidden: false,
        },
      ];

      

      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          datasets: datasets,
        },
        options: {
          plugins: {
            legend: {
              position: "right",
              labels: {
                color: textColor,
                font: {
                  size: 14,
                  family: "Regular",

                  
                },
                boxWidth: 20,
                boxHeight: 20,
              },
            },
          },
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "hour",
              },
              title: {
                display: true,
                text: "Time",
                color: textColor,
              },
              ticks: {
                color: textColor,
              },

            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Value",
                color: textColor,
              },
              ticks: {
               color: textColor, 
              },
            },
          },
        },
      });
    }
  }, [
    historicalData1,
    historicalData2,
    historicalData3,
    historicalData4,
    selectedSensorId,
    isDarkMode
  ]);

 

  return (
    <div className="historical-data-chart">
      <canvas ref={chartRef} id="historicalDataChart"></canvas>
    </div>
  );
};

export default HistoricalDataChart;
